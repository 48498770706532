const config = {
    "version": "v1",
    "config": {
      "visState": {
        "filters": [],
        "layers": [
            {
                "id": "c1og4at",
                "type": "point",
                "config": {
                    "dataId": "ais_layer",
                    "label": "ais",
                    "color": [121,178,124],
                    "columns": {
                    "lat": "ais_lat",
                    "lng": "ais_lon",
                    "altitude": null
                    },
                    "isVisible": true,
                    "visConfig": {
                    "radius": 10,
                    "fixedRadius": false,
                    "opacity": 0.8,
                    "outline": false,
                    "thickness": 2,
                    "strokeColor": null,
                    "colorRange": {
                        "name": "Global Warming",
                        "type": "sequential",
                        "category": "Uber",
                        "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                        ]
                    },
                    "strokeColorRange": {
                        "name": "Global Warming",
                        "type": "sequential",
                        "category": "Uber",
                        "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                        ]
                    },
                    "radiusRange": [
                        0,
                        50
                    ],
                    "filled": true
                    },
                    "hidden": false,
                    "textLabel": [
                    {
                        "field": null,
                        "color": [
                        255,
                        255,
                        255
                        ],
                        "size": 18,
                        "offset": [
                        0,
                        0
                        ],
                        "anchor": "start",
                        "alignment": "center"
                    }
                    ]
                },
                "visualChannels": {
                    "colorField": null,
                    "colorScale": "quantile",
                    "strokeColorField": null,
                    "strokeColorScale": "quantile",
                    "sizeField": null,
                    "sizeScale": "linear"
                }
            }
        ],
        "interactionConfig": {
          "tooltip": {
            "fieldsToShow": {
              "2uaj27o": [
                {
                  "name": "shipyardId",
                  "format": null
                },
                {
                  "name": "shipyardName",
                  "format": null
                },
                {
                  "name": "portId",
                  "format": null
                },
                {
                  "name": "updatedAt",
                  "format": null
                }
              ],
              "u9oxljs": [
                {
                  "name": "anchorageId",
                  "format": null
                },
                {
                  "name": "anchorageName",
                  "format": null
                },
                {
                  "name": "anchorageType",
                  "format": null
                },
                {
                  "name": "primaryPort",
                  "format": null
                },
                {
                  "name": "updatedAt",
                  "format": null
                }
              ],
              "qtolxcr": [
                {
                  "name": "portId",
                  "format": null
                },
                {
                  "name": "portName",
                  "format": null
                },
                {
                  "name": "unlocode",
                  "format": null
                },
                {
                  "name": "sailThrough",
                  "format": null
                },
                {
                  "name": "marineTrafficUrl",
                  "format": null
                }
              ],
              "mkh7jar": [
                {
                  "name": "berthId",
                  "format": null
                },
                {
                  "name": "berthName",
                  "format": null
                },
                {
                  "name": "berthType",
                  "format": null
                },
                {
                  "name": "updatedAt",
                  "format": null
                },
                {
                  "name": "portId",
                  "format": null
                }
              ],
              "ais_data": [
                {
                  "name": "ais_timestamp",
                  "format": null
                }
              ]
            },
            "compareMode": false,
            "compareType": "absolute",
            "enabled": true
          },
          "brush": {
            "size": 0.5,
            "enabled": false
          },
          "geocoder": {
            "enabled": false
          },
          "coordinate": {
            "enabled": false
          }
        },
        "layerBlending": "normal",
        "splitMaps": [],
        "animationConfig": {
          "currentTime": null,
          "speed": 1
        }
      },
      "mapStyle": {
        "styleType": "dark",
        "topLayerGroups": {},
        "visibleLayerGroups": {
          "label": true,
          "road": true,
          "border": false,
          "building": true,
          "water": true,
          "land": true,
          "3d building": false
        },
        "threeDBuildingColor": [
          9.665468314072013,
          17.18305478057247,
          31.1442867897876
        ],
        "mapStyles": {}
      }
    }
  }
  
  export default config;