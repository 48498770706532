export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const STORE_MAP_STATE = "STORE_MAP_STATE";

export const setSearchValue = (val) => {
  return {
    type: SET_SEARCH_VALUE,
    payload: { data: val }
  };
}

export const storeMapState = (mapState) => {
  return {
    type: STORE_MAP_STATE,
    payload: { data: mapState }
  };
}