import React, {useEffect, useRef, useState} from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import styled, {ThemeProvider} from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import {theme} from 'kepler.gl/styles';
import KeplerGlSchema from 'kepler.gl/schemas';
// Sample data
/* eslint-disable no-unused-vars */
import {addDataToMap, ActionTypes} from 'kepler.gl/actions';
import {processGeojson} from 'kepler.gl/processors';
/* eslint-enable no-unused-vars */

import { Loading } from "../components";
import Button from '../components/map-control/map-button';
import {replaceLoadDataModal} from '../factories/load-data-modal';
import {replaceMapControl} from '../factories/map-control';
import {replacePanelHeader} from '../factories/panel-header';
import {AUTH_TOKENS} from '../constants/default-settings';
import { storeMapState } from '../actions';

import MapLayerConfig from '../data/oceanbolt-config';
import AisConfig from '../data/ais-config';

import {
  onExportFileSuccess,
  onLoadCloudMapSuccess
} from '../actions';

const KeplerGl = require('kepler.gl/components').injectComponents([
  replaceLoadDataModal(),
  replaceMapControl(),
  replacePanelHeader()
]);

const keplerGlGetState = state => state.demo.keplerGl;

const apiUrl = process.env.REACT_APP_API_URL;

const GlobalStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-family: ff-clan-web-pro, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.71429;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.labelColor};
  }
`;

const LAYER_COLORS = {
  "ais_layer": [121,178,124],
  "ports_layer": [82, 96, 255],
  "berths_layer": [52, 235, 58],
  "shipyards_layer": [230, 222, 0],
  "anchorages_layer": [176, 17, 5],
}

const Map = ({ props }) => {
  const dispatch = useDispatch();
  const root = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { app: { searchKey } } = props;

  const getMapConfig = () => {
    // create the config object
    return KeplerGlSchema.getConfigToSave(props.demo.keplerGl);
  }

  const fetchData = async (url) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      return responseData;
      
    } catch (error) {
    }
  };

  const formatPortData = (ports) => {
    const data = [];

    for (let i = 0; i < ports.length; i += 1) {
      const port = ports[i];
      const properties = { ...port };
      delete properties['geometryPolygon']
      delete properties['geometryCoordinates']

      if (port.geometryPolygon) {
        data.push({
          type: 'Feature',
          properties: properties,
          geometry: JSON.parse(port.geometryPolygon)
        })
      }

      if (port.geometryCoordinates) {
        data.push({
          type: 'Feature',
          properties: properties,
          geometry: JSON.parse(port.geometryCoordinates)
        })
      }
    };

    return data;
  }

  const _loadGeoJsonData = async (apiUrl) => {
    // load Port data
    const portData = await fetchData(apiUrl);
    const keys = Object.keys(portData);

    if (portData && keys.length > 0) {
      const postGeoData = {
        type: 'FeatureCollection',
        features: formatPortData(portData[keys[0]])
      }

      return {
        info: {
          id: `${keys[0]}_layer`,
          label: `${keys[0]} data`,
          color: LAYER_COLORS[`${keys[0]}_layer`]
        },
        data: processGeojson(postGeoData),
        color: LAYER_COLORS[`${keys[0]}_layer`]
      }
    }
  }

  const formatResponseFromAIS = (responseData) => {
    return responseData && responseData.vesselPositions
      ? responseData.vesselPositions.map(entry => [entry.lat, entry.lon, entry.timestamp])
      : []
  }

  const _loadPointData = async () => {
    setLoading(true);
    var res =  await Promise.all([
      _loadGeoJsonData(`${apiUrl}/polygons/ports`),
      _loadGeoJsonData(`${apiUrl}/polygons/berths`),
      _loadGeoJsonData(`${apiUrl}/polygons/anchorages`),
      _loadGeoJsonData(`${apiUrl}/polygons/shipyards`),
    ]).then(res => {

      let data = [];
      for (var key in res) {
        data = data.concat(res[key]);
      }

      const mapData = {
        datasets: data,
        options: {
          keepExistingConfig: true
        },
        config: MapLayerConfig
      };

      dispatch(addDataToMap(mapData));
    });

    // setLoading(false);
    return res;
  }

  const _loadAISData = async (data) => {
    // show AIS data
    const start = "2015-01-01"
    const end = "2021-12-31"

    if (data) {
      let aisLayerConfig = AisConfig; 
      if (
        props.demo.keplerGl &&
        props.demo.keplerGl.map &&
        props.demo.keplerGl.map.visState.layers &&
        props.demo.keplerGl.map.visState.layers.length > 0
      ) {
        aisLayerConfig = getMapConfig();
      }

      if (!data.start || data.start === "") {
        data.start = start
      }
  
      if (!data.end || data.end === "") {
        data.end = end
      }

      setLoading(true);
      const responseData = await fetchData(`${apiUrl}/vessels/positions/historical?imo=${data.imo}&start_datetime=${data.start}T00%3A00%3A00&end_datetime=${data.end}T01%3A00%3A00&resolution=hourly`);
      setLoading(false);

      dispatch(
        addDataToMap({
          datasets: {
            info: {
              label: 'AIS data layer',
              id: 'ais_layer',
              color: LAYER_COLORS['ais_layer']
            },
            data: {
              fields: [
                {name: "ais_lat", format: "", type: "real"},
                {name: "ais_lon", format: "", type: "real"},
                {name: "ais_timestamp", format: "YYYY-M-DTH:m:s", type: "timestamp"}
              ],
              rows: formatResponseFromAIS(responseData)
            },
            color: LAYER_COLORS['ais_layer']
          },
          options: {
            keepExistingConfig: true
          },
          config: aisLayerConfig
        })
      );
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!props.app.map) {
      const _loadSampleLayer = async () => {
        await _loadPointData();
      }
      _loadSampleLayer();
    } else {
      dispatch(storeMapState(props.app.map));
    }
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    const _loadAISLayer = async (params) => {
      await _loadAISData(params);
    }

    // load sample data
    _loadAISLayer(searchKey);
  }, [searchKey]);

  useEffect(() => {
    if (props.demo.keplerGl && props.demo.keplerGl.map) {
      dispatch(storeMapState(props.demo.keplerGl.map));
    }
  }, [props.demo.keplerGl.map]);

  const _gotoHome = () => {
    history.push('/');
  }

  const {MAPBOX_TOKEN} = AUTH_TOKENS;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle
        // this is to apply the same modal style as kepler.gl core
        // because styled-components doesn't always return a node
        // https://github.com/styled-components/styled-components/issues/617
        ref={root}
      >
        <div
          style={{
            transition: 'margin 1s, height 1s',
            position: 'relative',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0
          }}
        >
          { loading ? <Loading /> : null }
          <Button onClick={_gotoHome}>Go Home</Button>
          <AutoSizer>
            {({height, width}) => (
              <KeplerGl
                mapboxApiAccessToken={MAPBOX_TOKEN}
                id="map"
                getState={keplerGlGetState}
                width={width}
                height={height}
                onExportToCloudSuccess={onExportFileSuccess}
                onLoadCloudMapSuccess={onLoadCloudMapSuccess}
              />
            )}
          </AutoSizer>
        </div>
      </GlobalStyle>
    </ThemeProvider>
  );
}

export default connect(
  state => ({ props: state })
)(Map)