import React, {useState} from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {useHistory} from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./logout-button";
import LoginButton from "./login-button";

import { setSearchValue } from '../actions/app';
import { isSearchKeyValid } from '../utils/number';

const MainNav = () => (
  <Nav className="mr-auto">
    <Nav.Link
      as={RouterNavLink}
      to="/"
      exact
      activeClassName="router-link-exact-active"
    >
      Home
    </Nav.Link>
    <Nav.Link
      as={RouterNavLink}
      to="/profile"
      exact
      activeClassName="router-link-exact-active"
    >
      Profile
    </Nav.Link>
    <Nav.Link
      as={RouterNavLink}
      to="/external-api"
      exact
      activeClassName="router-link-exact-active"
    >
      External API
    </Nav.Link>
    <Nav.Link
      as={RouterNavLink}
      to="/map"
      exact
      activeClassName="router-link-exact-active"
    >
      Map
    </Nav.Link>
  </Nav>
);

const AuthNav = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Nav className="justify-content-end">
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </Nav>
  );
};


const SearchBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value,setValue] = useState('');
  const [startDate,setStartDate] = useState('2020-01-01');
  const [endDate,setEndDate] = useState('2021-01-01');

  const _onChange = (e) => {
    setValue(e.target.value);
  }

  const _onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  }

  const _onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  }

  const _onSubmit = (e) => {
    console.log(e)
    if(e.key === 'Enter'){
      if (isSearchKeyValid(value)) {
        dispatch(setSearchValue({imo:value,start:startDate,end:endDate}));
        history.push('/map');
      } else {
        alert("Please type number between 1000000 and 9999999!");
      }
    }
  }

  return (
    <div className="mr-3">
      <input className="mr-1"
        type="date"
        value={startDate}
        onChange={_onChangeStartDate}
      />
      <input className="mr-1"
        type="date"
        value={endDate}
        onChange={_onChangeEndDate}
      />
      <input className="mr-1"
        type="text"
        placeholder="Search..."
        value={value}
        onChange={_onChange}
        onKeyDown={_onSubmit}
      />
    </div>
  );
}

const NavBar = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Navbar expand="md">
      
        
        
        {
          isAuthenticated ? <Container><Navbar.Brand as={RouterNavLink} to="/" /><MainNav /><SearchBox /> <AuthNav /></Container>: null
        }
        
      
    </Navbar>
  );
};

export default NavBar;
