import React from "react";

const logo = "https://storage.googleapis.com/ob-public-assets/web/oceanbolt_logo_square.png";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Oceanbolt Internal Visualization Tool</h1>

    <p className="lead">
      This is an internal tool used for visualization of AIS data{" "}<br></br>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://kepler.gl"
      >
        Based on Kepler.gl
      </a>
    </p>
  </div>
);

export default Hero;
