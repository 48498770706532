import React, { useState } from "react";
import { Button, ButtonGroup, Container } from "react-bootstrap";
import { Highlight } from "../components";
import { useAuth0 } from "@auth0/auth0-react";

export const ExternalApi = () => {
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const { getAccessTokenSilently } = useAuth0();

  const fetchAisData = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log(token);

      const response = await fetch(`${apiUrl}/vessels/positions/historical?imo=9498224&start_datetime=2020-01-01T00%3A00%3A00&end_datetime=2020-12-31T01%3A00%3A00`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        
      });

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const fetchPortData = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/polygons/ports`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
        
      });

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const fetchBerthData = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/polygons/berths`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
        
      });

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const fetchAnchorageData = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/polygons/anchorages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
        
      });

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const fetchShipyardData = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/polygons/shipyards`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
        
      });

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <Container className="my-5">
      <h1>External API Data</h1>
      <p>
        Data for Kepler.gl should be loaded from the below API
      </p>
      <ButtonGroup>
      
        <Button onClick={fetchAisData} color="primary" className="mt-5">
          Get AIS Data
        </Button>

        <Button onClick={fetchPortData} color="primary" className="mt-5">
          Get Port Data
        </Button>

        <Button onClick={fetchAnchorageData} color="primary" className="mt-5">
          Get Anchorage Data
        </Button>

        <Button onClick={fetchBerthData} color="primary" className="mt-5">
          Get Berth Data (STILL TBD)
        </Button>

        <Button onClick={fetchShipyardData} color="primary" className="mt-5">
          Get Shipyard Data  (STILL TBD)
        </Button>
      </ButtonGroup>

      {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <Highlight>{JSON.stringify(message, null, 2)}</Highlight>
        </div>
      )}
    </Container>
  );
};

export default ExternalApi;
