import {handleActions} from 'redux-actions';

import {
  SET_SEARCH_VALUE,
  STORE_MAP_STATE
} from '../actions/app';

const initialAppState = {
  searchKey: '',
  error: null,
  map: null
}

const appReducer = handleActions(
  {
    [SET_SEARCH_VALUE]: (state, action) => ({
      ...state,
      searchKey: action.payload.data
    }),
    [STORE_MAP_STATE]: (state, action) => {
      return {
        ...state,
        map: action.payload.data
      }
    }
  },
  initialAppState
);

export default appReducer;