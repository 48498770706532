import React from "react";
import { Container, Nav } from "react-bootstrap";

import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/logout-button";
import LoginButton from "../components/login-button";
import { Hero } from "../components";

const AuthNav = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Nav className="justify-content-center">
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </Nav>
  );
};


const Home = () => (
  <Container className="my-5">
    <Hero />
    <hr />
    <AuthNav />

  </Container>
);

export default Home;
